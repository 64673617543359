
.NodeModalHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 3rem;
}

.NodeModalWrapper{
    width: 100rem;
    height: 60rem;
    max-width: 100rem;
    max-height: 60rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    position: absolute;
    flex-wrap: wrap;
    transform: translate(-50%, -50%);
    gap: 0.5rem;
    overflow: hidden;
}

.NodeModalContentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 49rem;
    height: 56rem;
    overflow: auto;
    elevation: below;
}

.NodeModalContentContainerHalfSize{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 49rem;
    height: 28rem;
    overflow: auto;
    elevation: below;
}