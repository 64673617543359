.markerGateway{
    /* Set the marker size here */
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    /* Set the marker color here */
    background: #215a53;
    left: -0.5rem;
    top: -2rem;

    display: flex;
    border-bottom-right-radius: 0;
    position: relative;
    transform: rotate(45deg);
    justify-content: center;
    align-items: center;
    /* optional fanciness */
    border: 1px solid #215a53;
    transition: all 0.5s;
}

.markerGateway:hover{
    /* Set the marker size here */
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    /* Set the marker color here */
    background: #215a53;
    left: -0.5rem;
    top: -2rem;

    display: flex;
    border-bottom-right-radius: 0;
    position: relative;

    justify-content: center;
    align-items: center;
    /* optional fanciness */

    transition: all 0.5s;

    border: 1px solid #00faa3;
    transform: scale(1.5) rotate(45deg);
}

.markerGatewayHighlight{
    background: #215a53;
    border: 1px solid #00faa3;
    transform: scale(1.5) rotate(45deg);

}