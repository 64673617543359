.marker {
    /* Set the marker size here */
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    /* Set the marker color here */
    background: #33B7A8;
    left: -0.5rem;
    top: -2rem;

    display: flex;
    border-bottom-right-radius: 0;
    position: relative;
    transform: rotate(45deg);
    justify-content: center;
    align-items: center;
    /* optional fanciness */
    border: 1px solid #00A693;
    transition: all 0.5s;
}

.marker:hover{
    transform: scale(1.2) rotate(45deg);
    border: 1px solid #00faa3;
    transition: all 0.5s;
}

.markerHighlight{
    background: #03855a;
}

.markerPopup{
    margin-bottom: 3rem;
}

.geodiscLine{
    stroke:#00A693;
    transition: all 0.5s;
    opacity: 80%;
}
.geodiscLine:hover{
    color:#03855a;
    width: 30px;
    stroke: #00faa3;
}
.marker #svg{
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
}
/* inner circle (optional if you don't need the central dot) */
/*.marker::before {
    content: "";
    background: white;
    width: 50%;
    height: 50%;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}*/
/* shadow (optional if you don't need a shadow) */
.marker::after {
    content: "";
    background: rgba(128, 128, 128, 0.2);
    width: 75%;
    height: 75%;
    border-radius: 100%;
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-50%, -50%) rotate(45deg) scaleX(0.5);
}