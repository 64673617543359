
.infoarea-button{
    height: 2vh;
    width: 2.5vw;
}
.infoarea-button:hover{
    display: none;
}
.infoarea{
    position: absolute;
    top: calc( 50vh - 1.25vh );
    right: -1.25vw;
    width: 2.5vw;
    height: 2.5vw;
    transition: all 0.5s ease-in;
    overflow: hidden;
    background-color: #03855a;
    padding-top: 2.5vw;
    border-radius: 100%;
}

.infoarea:hover{
    border-radius: 0.5rem 0 0 0.5rem;
    padding-top: 0;
    background: white;
    transform: translate(-1.25vw, -48.75vh );
    height: 100vh;
    width: 20vw;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.infoarea:after{

    position: absolute;
    top: calc( 50vh - 1.25vh );
    right: -1.25vw;
    width: 2.5vw;
    height: 2.5vw;
    transition: transform 0.5s;
    overflow: hidden;
    background-color: #03855a;
    padding-top: 2.5vw;
    border-radius: 100%;


}
